import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency2 } from '../../util/valueCalculator';
import { fetchUsuarioById } from '../../reducers/usuario_slice';
import { modifyCajaDiaria } from '../../reducers/caja_slice'; // Asegúrate que esta acción llame al nuevo endpoint
import { fetchAllDepositos } from '../../reducers/deposito_slice';
import { fetchReciboById, fetchRecibos } from '../../reducers/recibo_slice';
import { fetchBancos } from '../../reducers/banco_slice';
import Swal from 'sweetalert2';
import ImprimirCajaDiaria from './ImprimirCajaDiaria';
import { formatearFechaSimple } from '../../util/customFormatter';

const CajaDiariaCierre = ({ show, handleClose, cajaDiaria, detallesRecibo }) => {
    const dispatch = useDispatch();
    const depositos = useSelector(state => state.deposito.depositos || []);
    const bancos = useSelector(state => state.banco.bancos || []);
    const recibos = useSelector(state => state.recibo.recibos || []);
    const reciboActual = useSelector(state => state.recibo.current || {});
    const [usuario, setUsuario] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [totals, setTotals] = useState({
        ingresosCaja: 0,
        egresosCaja: 0,
        ingresosBanco: 0,
        egresosBanco: 0,
    });

    const { nombre } = useSelector(state => state.sesion);

    // Obtener fecha y hora actual
    const today = new Date().toLocaleDateString('es-BO', {
        timeZone: 'America/La_Paz',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');

    const currentHour = new Date().toLocaleTimeString('es-BO', { 
        hour: '2-digit', 
        minute: '2-digit',
        hour12: false 
    });

    // Cargar datos necesarios
    useEffect(() => {
        dispatch(fetchRecibos());
    }, [dispatch]);

    useEffect(() => {
        if (cajaDiaria.id_usuario) {
            dispatch(fetchUsuarioById(cajaDiaria.id_usuario))
                .then(response => {
                    if (response.payload) {
                        setUsuario(response.payload);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener el usuario:', error);
                });
        }
    }, [cajaDiaria.id_usuario, dispatch]);

    // Calcular totales
    useEffect(() => {
        const detallesFiltrados = detallesRecibo.filter(det => det.fecha === cajaDiaria.fechaAper);

        const ingresosCaja = detallesFiltrados
            .filter(det => det.pago === 'C' && det.tipo === 'INGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        const egresosCaja = detallesFiltrados
            .filter(det => det.pago === 'C' && det.tipo === 'EGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        const ingresosBanco = detallesFiltrados
            .filter(det => det.pago === 'B' && det.tipo === 'INGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        const egresosBanco = detallesFiltrados
            .filter(det => det.pago === 'B' && det.tipo === 'EGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        setTotals({
            ingresosCaja,
            egresosCaja,
            ingresosBanco,
            egresosBanco,
        });
    }, [detallesRecibo, cajaDiaria]);

    useEffect(() => {
        if (show) {
            dispatch(fetchAllDepositos());
            dispatch(fetchBancos());
        }
    }, [dispatch, show]);

    // Función para manejar el cierre de caja
    const handleCloseCaja = async () => {
        const confirmation = await Swal.fire({
            title: '¿Confirmar cierre de caja?',
            text: 'Esta acción registrará todos los movimientos y no se podrá modificar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cerrar caja',
            cancelButtonText: 'Cancelar'
        });

        if (confirmation.isConfirmed) {
            try {
                // Preparar datos para el backend según el controlador
                const cierreData = {
                    ingresos: totals.ingresosCaja + totals.ingresosBanco,
                    egresos: totals.egresosCaja + totals.egresosBanco,
                    fechaCierre: today,
                    horaCierre: currentHour,
                    fechaApertura: cajaDiaria.fechaAper // Enviar fecha de apertura para referencia
                };

                // Llamar a la acción que ejecutará el endpoint updateCajaDiariaById
                await dispatch(modifyCajaDiaria({
                    id: cajaDiaria.id,
                    cajaDiaria: cierreData
                })).unwrap();

                // Mostrar mensaje de éxito
                Swal.fire('Éxito', 'Caja cerrada correctamente', 'success');
                
                // Generar datos para el reporte
                setReportData({
                    caja: cajaDiaria,
                    usuario,
                    cierreData,
                    movimientos: detallesRecibo.filter(det => det.fecha === cajaDiaria.fechaAper),
                    totals
                });
                
                // Mostrar el modal de impresión
                setShowPrintModal(true);
                
                // Cerrar el modal de cierre
                handleClose();
            } catch (error) {
                console.error('Error al cerrar caja:', error);
                Swal.fire('Error', error.message || 'Ocurrió un error al cerrar la caja', 'error');
            }
        }
    };

    // Función para agrupar recibos para la tabla
    const agruparRecibosParaTabla = () => {
        const recibosUnicos = [...new Set(detallesRecibo
            .filter(det => det.fecha === cajaDiaria.fechaAper)
            .map(det => det.id_recibo))];

        return recibosUnicos.map(idRecibo => {
            const detalles = detallesRecibo.filter(det => det.id_recibo === idRecibo && det.fecha === cajaDiaria.fechaAper);
            const recibo = recibos.find(r => r.id === idRecibo) || {};
            const tieneProyecto = detalles[0]?.id_proyecto !== null;

            if (tieneProyecto) {
                // Consolidar recibos de proyecto
                const ingresoCaja = detalles
                    .filter(det => det.pago === 'C' && det.tipo === 'INGRESO')
                    .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);
                
                const ingresoBanco = detalles
                    .filter(det => det.pago === 'B' && det.tipo === 'INGRESO')
                    .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

                return {
                    nroRecibo: recibo.nroRecibo || '',
                    concepto: recibo.concepto || 'Recibo asociado a proyecto',
                    ingresoCaja,
                    ingresoBanco,
                    esProyecto: true
                };
            } else {
                // Mostrar detalles individuales para recibos sin proyecto
                return detalles.map(detalle => ({
                    nroRecibo: recibo.nroRecibo || '',
                    concepto: detalle.concepto,
                    ingresoCaja: detalle.pago === 'C' && detalle.tipo === 'INGRESO' ? parseFloat(detalle.pagado || 0) : 0,
                    ingresoBanco: detalle.pago === 'B' && detalle.tipo === 'INGRESO' ? parseFloat(detalle.pagado || 0) : 0,
                    esProyecto: false
                }));
            }
        }).flat();
    };

    const itemsTabla = agruparRecibosParaTabla();
    const [reportData, setReportData] = useState(null);

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Cierre de Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p><strong>Fecha apertura:</strong> {formatearFechaSimple(cajaDiaria.fechaAper)}</p>
                        <p><strong>Caja:</strong> {cajaDiaria.nrocaja}</p>
                        <p><strong>Usuario:</strong> {nombre}</p>
                    </div>
                    
                    <Table bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nro Recibo</th>
                                <th>Concepto</th>
                                <th className="text-end">INGRESO CAJA</th>
                                <th className="text-end">INGRESO BANCO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemsTabla.map((item, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.nroRecibo}</td>
                                    <td>{item.concepto}</td>
                                    <td className="text-end">
                                        {item.ingresoCaja > 0 ? formatCurrency2(item.ingresoCaja) : '-'}
                                    </td>
                                    <td className="text-end">
                                        {item.ingresoBanco > 0 ? formatCurrency2(item.ingresoBanco) : '-'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className="table-active">
                                <th colSpan="3">TOTALES</th>
                                <th className="text-end">{formatCurrency2(totals.ingresosCaja)}</th>
                                <th className="text-end">{formatCurrency2(totals.ingresosBanco)}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={handleCloseCaja}>
                        Cerrar Caja
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de reporte */}
            {reportData && (
                <ImprimirCajaDiaria
                    show={showPrintModal}
                    handleClose={() => setShowPrintModal(false)}
                    data={reportData}
                />
            )}
        </>
    );
};

export default CajaDiariaCierre;