import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util/customFormatter";
import { BsCamera, BsEye, BsEyeSlash, BsUpload } from "react-icons/bs";
import { getUploadUrl } from "../../util/valueCalculator";
import { useState } from "react";
import { validationFn } from "../../hooks/useInputFile";

const TabPago = ({ tipoProceso, errors, 
    datos,
    esLote,
    tipoPago,
    depositos,
    handleChangeDeposito,
    paymentSaved,
    handleFileDepositoChange,
    startCamera,
    handleAddDeposito,
    handleRemoveDeposito,
    handleChangeTipoPago,
    handleChangeDescuento,
    esResellado,
    totalPago,
    handleProforma ,
    setDatos,
    setErrors
}) => {
const bancos = useSelector(state => state.banco.bancos || []);
const { rolActivo, id_afiliado, id:idUser } = useSelector(state => state.sesion);
const pagosVisados= datos.recibos?.filter(reci => reci.concepto.toLowerCase().includes("visado") ||  reci.concepto.toLowerCase().includes("resellado"))
 const [imagenVisible, setImagenVisible] = useState([]);
 const [imagenPreview, setImagenPreview] = useState(null);

 const toggleImageVisibility = async(index) => {
    const newVisibility = [...imagenVisible];
    newVisibility[index] = !newVisibility[index];
    setImagenVisible(newVisibility.map((visible, i) => (i === index ? visible : false)));
    const archivoViejo = depositos[index].archivo && depositos[index].tipo==="previo"
    const archivoNuevo = depositos[index].archivo && depositos[index].tipo==="nuevo"
    const validImage = validationFn('image',depositos[index].archivo);
    const imagen = archivoViejo? getUploadUrl(depositos[index].archivo.name): archivoNuevo? depositos[index].archivo :null;

    if (newVisibility[index] && validImage === "image") {
        const response = archivoViejo? await fetch(imagen) : imagen;
        const blob = archivoViejo? await response.blob() : response;

      const reader = new FileReader();
      reader.onload = (e) => setImagenPreview(e.target.result);
      reader.readAsDataURL(blob);
    } else {
      setImagenPreview(null);
    }
  };
    return (
        <>
          <Row className={`${errors.depositos ? 'border-danger' : ''}`}>
                  
                  {/* Mostrar los recibos previos */}
                    {(tipoProceso!=="transferir" && pagosVisados && pagosVisados.length > 0) && (
                        <div className="mb-3">
                            <h5>Pagos Realizados</h5>
                            <Card className="p-3">
                                <Card.Body>
                                    {pagosVisados.map((recibo, index) => (
                                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                            <div style={{ fontWeight: 'bold', color: '#495057' }}>N° recibo: {recibo.nroRecibo}</div>
                                            <div style={{ color: '#495057' }}>{recibo.concepto}</div>
                                            <div style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                                                {formatNumber(recibo.monto)} Bs.
                                            </div>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        </div>
                    )}
            
                {(tipoProceso!=="transferir" || rolActivo?.alias==="gerente" || rolActivo?.alias==="secretaria") &&(
                <>
                {tipoPago==="B"&&  <div className={`table-container ${errors.depositos ? 'border-danger' : ''}`}>
                        <h5>Registrar Depósitos</h5>
                        <Table bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th >Banco</th>
                                    <th>Monto</th>
                                    <th>Fecha Depósito</th>
                                    <th>N° Ref. Dep.</th>
                                    <th style={{width: '200px'}}>Foto de respaldo</th>
                                    <th style={{width:"50px"}}>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {depositos.map((deposito, index) => {
                                    const nombreArchivo = deposito.archivo ? deposito.archivo.name
                                                                 : "- Sin seleccionar -";
                                    const fotoDetalle = deposito.archivo 
                                    ? deposito.tipo==="nuevo"?  URL.createObjectURL(deposito.archivo) 
                                    : getUploadUrl(deposito.archivo.name) : "";
                                    
                                    return(
                                    <tr key={index}>
                                        <td style={{ width: '200px' }}>
                                            <Form.Control
                                                as="select"
                                                value={deposito.id_banco}
                                                onChange={(e) => handleChangeDeposito(index, 'id_banco', e.target.value)}
                                                disabled={paymentSaved}
                                            >
                                                <option value="">- Sin seleccionar -</option>
                                                {bancos.map((banco) => (
                                                    <option key={banco.id} value={banco.id}>
                                                        {banco.sigla}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </td>
                                        <td style={{ width: '150px' }}>
                                            <Form.Control
                                                type="number"
                                                value={deposito.monto}
                                                onChange={(e) => handleChangeDeposito(index, 'monto', e.target.value)}
                                                required
                                                disabled={paymentSaved}
                                            />
                                        </td>
                                        <td style={{ width: '150px' }}>
                                            <Form.Control
                                                type="date"
                                                value={deposito.fecha_deposito}
                                                onChange={(e) => handleChangeDeposito(index, 'fecha_deposito', e.target.value)}
                                                required
                                                disabled={paymentSaved}
                                            />
                                        </td>
                                        <td style={{ width: '100px' }}>
                                            <Form.Control
                                                type="text"
                                                value={deposito.docum}
                                                onChange={(e) => handleChangeDeposito(index, 'docum', e.target.value)}
                                                disabled={paymentSaved}
                                            />
                                        </td>
                                        <td style={{width: '250px', display: 'flex', alignItems: 'center' }} className="soft-labels">
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => handleFileDepositoChange(index, e.target.files[0])}
                                                disabled={paymentSaved}
                                                style={{ display: 'none' }} 
                                                id={`file-input-${index}`} 
                                            />
                                            {/* Creamos un botón personalizado para activar el file input */}
                                            <label title="Subir archivo" htmlFor={`file-input-${index}`} className="icon-button" style={{ cursor: 'pointer', padding:"2px 6px 4px 6px" }}>
                                            <BsUpload /> {!deposito.archivo && "- Subir archivo -"}
                                            </label>
                                            {deposito.archivo && (
                                                <>
                                                    <span style={{ marginLeft: '10px' }}>
                                                        
                                                    <a
                                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                        href={fotoDetalle}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title='Click para ver en una nueva pestaña'
                                                    >
                                                        {nombreArchivo}
                                                    </a>
                                                    </span>
                                                    <button
                                                        className={`icon-button ${imagenVisible[index]? "btn-mine-secondary":""}`}
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => toggleImageVisibility(index)}
                                                        title="Ver imagen"
                                                        type="button"
                                                    >
                                                        {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                                                    </button>
                                                </>
                                            )}
                                            <button
                                                className="icon-button camara-button"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => startCamera(index)}
                                                type="button"
                                            >
                                                <BsCamera />
                                            </button>
                                        </td>
                                        <td style={{width:"50px"}}>
                                            {!paymentSaved && index === depositos.length - 1 && (
                                                <button className="icon-button" onClick={handleAddDeposito} type="button">
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            )}
                                            {!paymentSaved && depositos.length > 1 && (
                                                <button className="icon-button" onClick={() => handleRemoveDeposito(index)}  type="button">
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                )})}
                            </tbody>
                        </Table>
                        {errors.depositos && (
                        <p className="text-danger mt-2">
                            Todos los datos de los depósitos deben ser llenados, incluyendo el archivo de respaldo correspondiente a cada uno.
                        </p>
                    )}
                    </div>}
                    <Col className="soft-labels mt-3 mb-3">
                {esLote &&
                            <Form.Check
                                type="checkbox"
                                id="efectivo-checkbox"
                                label="Pago en efectivo"
                                onChange={(e)=>{handleChangeTipoPago(e);setImagenPreview(null)}}
                                checked={tipoPago==="C"}
                            />
                            
                       } </Col>
                        {imagenPreview && tipoPago==="B"&&(
                        <img
                            src={imagenPreview}
                            alt="Imagen de Depósito"
                            style={{
                            top: '10px',
                            right: '10px',
                            maxWidth: '400px',
                            border: '1px solid #ccc',
                            padding: '5px',
                            borderRadius: '5px',
                            backgroundColor: '#fff'
                            }}
                        />
                        )}
                   </>)} <div>
                    {esResellado && <><strong>Visación:</strong>{" "}
                        <span style={{ fontWeight: 'bold' }}>{formatNumber(datos.comision_cat)} Bs.</span>
                        </>
                     }
                        {esResellado && <>
                        <br></br>
                       {tipoProceso === "editar" &&<><strong>Total pagos realizados:</strong>{' '}
                        <span style={{ fontWeight: 'bold' }}>{formatNumber(totalPago.pagadoAnteriormente)} Bs.</span>
            </>}
                      </>}
                   
                     
                       {(esResellado) && <hr></hr>}
                        <strong>Monto a pagar:</strong>{' '}
                        <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalPago.porPagar)} Bs.</span>


                        {datos.conceptoDescuento === "viviendapropia"&&<> 
                            <br></br>
                      <strong>Descuento:</strong>{' '}
                        <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalPago.descuento)} Bs.</span>
                        <hr></hr>
                        <strong>Total con descuento:</strong>{' '}
                        <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalPago.totalConDescuento)} Bs.</span>
                       
                        </>}
                        <br></br>
                        <strong>Total pagado:</strong>{' '}
                        <span style={{ fontWeight: 'bold' }}>{formatNumber(totalPago.pagado)} Bs.</span>
                
                        {errors.totalPago && (
                            <div className="invalid-feedback d-block">
                                El monto "Total pagado" debe ser igual al "Monto a pagar" calculado.
                            </div>
                        )}
                    </div>
 
                
                </Row>

        </>
    )
}

export default TabPago;