import React, { useRef, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { updateValoradoVen } from '../../reducers/valoradoVen_slice';
import CustomPrintCert2 from './CustomPrintCert2';
import { calculateAge } from '../../util/valueCalculator';
import { nombreApellidosFullPersona } from '../../util/customFormatter';

const AfiliadoCert2 = ({ show, handleClose, afiliado, certificadoData }) => {
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [qrDataURL, setQrDataURL] = useState('');
    const [certObjectText, setCertObjectText] = useState('');
    const [printDisabled, setPrintDisabled] = useState(false); // Botón inhabilitado solo en "Imprimir"
    const [isDraft, setIsDraft] = useState(false); // Estado para determinar si es borrador
    const qrRef = useRef();
    const dispatch = useDispatch();

    const { idValoradoVen, numeroCertificado, usado } = certificadoData;

    const tiempoServicio = calculateAge(afiliado.fecha_reg_cab, new Date());

    const handleGenerateQR = () => {
        const canvas = qrRef.current.querySelector('canvas');
        const qrImageData = canvas.toDataURL('image/png');
        setQrDataURL(qrImageData);
    };

    const handlePrintConfirmation = async () => {
        const result = await Swal.fire({
            title: 'Confirmación',
            text: 'Este proceso es irreversible. Por favor, verifica los datos antes de proceder.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            try {
                const updateResponse = await dispatch(
                    updateValoradoVen({ id: idValoradoVen, usado: usado - 1 })
                ).unwrap();

                if (updateResponse) {
                    handleGenerateQR();
                    setShowPrintModal(true);
                    setPrintDisabled(true); // Inhabilitar el botón solo en impresión
                    setIsDraft(false); // No es borrador
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo actualizar el certificado. Intenta nuevamente.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    };

    const handleDraftPrint = () => {
        handleGenerateQR();
        setShowPrintModal(true);
        setIsDraft(true); // Indicar que es borrador
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
        if (!isDraft) {
            setPrintDisabled(true); // Solo inhabilitar si no es borrador
        }
    };

    const handlePrint = () => {
        handlePrintConfirmation();
    };

    const handleObjectTextChange = (e) => {
        setCertObjectText(e.target.value);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles del Certificado de Licitación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-4">
                        <Col>
                            <h5>Datos del Afiliado</h5>
                            <p><strong>Nombre :</strong> {`${nombreApellidosFullPersona(afiliado.persona)}`}</p>
                            <p><strong>Número de Registro:</strong> {afiliado.num_reg}</p>
                            <p><strong>CI:</strong> {afiliado.persona.ci}</p>
                            <p><strong>Tiempo de Servicio:</strong> {`${tiempoServicio.years} años, ${tiempoServicio.months} meses, y ${tiempoServicio.days} días`}</p>
                        </Col>
                        <Col className="text-center" ref={qrRef}>
                            <h5>QR del Certificado</h5>
                            <QRCodeCanvas
                                value={`Certificado: ${numeroCertificado}, Nombre: ${nombreApellidosFullPersona(afiliado.persona,"ci")}, Registro: ${afiliado.num_reg}`}
                                size={128}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Objeto del Certificado</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Escribe el objeto del certificado"
                                    value={certObjectText}
                                    onChange={handleObjectTextChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handlePrint}
                        disabled={printDisabled} // Botón inhabilitado solo después de impresión definitiva
                    >
                        Generar Certificado
                    </Button>
                    <Button
                        variant="info"
                        onClick={handleDraftPrint}
                    >
                        Ver Borrador
                    </Button>
                </Modal.Footer>
            </Modal>

            {showPrintModal && (
                <CustomPrintCert2
                    show={showPrintModal}
                    handleClose={handlePrintModalClose}
                    afiliado={afiliado}
                    numeroCertificado={numeroCertificado}
                    aserv={`${tiempoServicio.years} años y ${tiempoServicio.months} meses`}
                    qrDataURL={qrDataURL}
                    certObjectText={certObjectText.toUpperCase()}
                    copia={isDraft} // Enviar como copia si es borrador
                />
            )}
        </>
    );
};

export default AfiliadoCert2;
