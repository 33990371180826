import React, { useEffect, useState } from "react";
import { Form, Carousel, Button, ListGroup } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { compressImage } from "../../hooks/useInputFile";
import { getUploadUrl } from "../../util/valueCalculator";
import { formatNumber } from "../../util/customFormatter";

const TabPagoRespaldo = ({ montoPorPagar, imagenes=null, setImagenes,tipo, proyId }) => {
  const [previews, setPreviews] = useState([]); // Array para almacenar las vistas previas de las imágenes
  const [activeIndex, setActiveIndex] = useState(0); // Índice de la imagen activa en el carrusel

  useEffect(() => {
   if(imagenes){ const previewsArray = [];

    for (const file of imagenes) {
        const previewUrl = getUploadUrl(file.nombre_archivo);
        previewsArray.push(previewUrl);
    }

    setPreviews(previewsArray);}
  },[imagenes])
  // Manejar la selección de archivos
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files); // Convertir FileList a array
    const compressedImages = [];
    const previewsArray = [];

    for (const file of files) {
        try {
          // Cambiar el nombre del archivo
          const renamedFile = new File([file], `respdep-proy${proyId}-${file.name}`, {
            type: file.type,
          });
    
          // Comprimir la imagen
          const compressedFile = await compressImage(renamedFile);
          compressedImages.push(compressedFile);
    
          // Crear vista previa
          const previewUrl = URL.createObjectURL(compressedFile);
          previewsArray.push(previewUrl);
        } catch (error) {
          console.error("Error al comprimir la imagen:", error);
        }
      }
    
    setPreviews((prev) => [...prev, ...previewsArray]); // Agregar vistas previas al array
    setImagenes((prev) => [...prev, ...compressedImages]); // Pasar imágenes comprimidas al componente padre
  };

  // Manejar la eliminación de una imagen
  const handleRemoveImage = (index) => {
    setPreviews((prev) => prev.filter((_, i) => i !== index)); // Eliminar la vista previa correspondiente
    setImagenes((prev) => prev.filter((_, i) => i !== index)); // Eliminar la imagen del componente padre
    if (activeIndex >= previews.length - 1) {
      setActiveIndex((prev) => Math.max(0, prev - 1)); // Ajustar el índice activo si se elimina la última imagen
    }
  };

  // Navegar al depósito anterior
  const handlePrev = () => {
    setActiveIndex((prev) => (prev > 0 ? prev - 1 : previews.length - 1));
  };

  // Navegar al depósito siguiente
  const handleNext = () => {
    setActiveIndex((prev) => (prev < previews.length - 1 ? prev + 1 : 0));
  };
 const titulo = tipo==="registrar"?"Registrar Depósitos":"Pagos realizados";
 return (
    <>
    <div style={{minHeight:"400px"}}>
   {tipo==="registrar" &&   <Form.Group controlId="formFileMultiple" className="mb-3">
        <Form.Label>Subir imágenes de depósitos</Form.Label>
        <Form.Control
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileChange}
        />
      </Form.Group>
}
      {/* Carrusel para mostrar las imágenes */}
      {previews.length > 0 && (
        <>
          <Carousel
            className="mb-3"
            controls={false}
            indicators={false}
            interval={null}
            activeIndex={activeIndex}
            onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
          >
            {previews.map((preview, index) => (
              <Carousel.Item key={index}>
                <div className="position-relative">
                  <img
                    className="d-block w-100"
                    src={preview}
                    alt={`Depósito ${index + 1}`}
                    style={{ maxHeight: "400px", objectFit: "contain" }}
                  />
                {tipo==="registrar" &&  <button
                    className="icon-button btn-cancel position-absolute"
                    style={{ top: "10px", right: "10px" }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>

          {previews.length > 1 && 
          <div className="d-flex justify-content-center align-items-center mb-3">
          <button className="icon-button btn-transparent me-2" onClick={handlePrev}>
                <i className="bi bi-caret-left"></i>
            </button>
            <p className="text-center mb-0">
                Depósito {activeIndex + 1} de {previews.length}
            </p> <button className="icon-button btn-transparent ms-2" onClick={handleNext}>
                <i className="bi bi-caret-right"></i>
            </button>
            </div>}
        </>
      )}
     
    </div>
      <ListGroup className='mb-4'>
      <ListGroup.Item className='fw-bold' style={{fontSize:"1.05rem"}}>Total por pagar: {formatNumber(montoPorPagar)} Bs.
      </ListGroup.Item>
      </ListGroup>
      </>
  );
};

export default TabPagoRespaldo;