import React, { useEffect, useState } from 'react';
import { getEstadoProyecto } from '../../util/valueCalculator';

const CustomBadge = ({ estado }) => {
  const [badgeInfo, setBadgeInfo] = useState({ titulo: '', bgColor: '' });

  useEffect(() => {
    const info = getEstadoProyecto(estado);
      
    let bgColor;
    switch (info.clase) {
      case 'borrador':
        bgColor = "#0080d5";  // Color para 'borrador'
        break;
      case 'activo':
        bgColor = "#0b944a";  // Color para 'activo'
        break;
      case 'revision':
        bgColor = "#cca209";  // Color para 'revision'
        break;
      case 'aprobado':
        bgColor = "#cc5e09";  // Color para 'aprobado'
        break;
      case 'pagado':
        bgColor = "#940b55";  // Color para 'aprobado'
        break;
      case 'inactivo':
        bgColor = "#9e9e9e";  // Color para 'anulado' o 'inactivo'
        break;
      case 'claro':
        bgColor = "#607d8b";  // Color para 'sin especificar'
        break;
      default:
        bgColor = "#607d8b";  // Color por defecto
    }
    setBadgeInfo({titulo:info.titulo, bgColor});
  }, [estado]);

  return (
    <span className="badge" style={{backgroundColor:badgeInfo.bgColor }}>
      {badgeInfo.titulo}
    </span>
  );
};

export default CustomBadge;
