import React, { useEffect, useState, useRef } from 'react';
import { Modal, Table, Button, Image, Spinner, Alert } from 'react-bootstrap';
import { BsEye, BsEyeSlash, BsUpload, BsCamera, BsPrinter, BsSave } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { getUploadUrl, formatearFecha, numeroALetras } from '../../util/valueCalculator';
import { fetchBancos } from '../../reducers/banco_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { updateDetalleArchivo } from '../../reducers/deposito_slice';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import { nombreApellidosFullPersona } from '../../util/customFormatter';
import { compressImage } from '../../hooks/useInputFile';


const DepositoForm = ({ show, onHide, deposito }) => {
  // ============ ESTADOS ============
  const [imagenPreview, setImagenPreview] = useState(null);
  const [imagenVisible, setImagenVisible] = useState([]);
  const [reciboData, setReciboData] = useState(null);
  const [showImprimirReciboModal, setShowImprimirReciboModal] = useState(false);
  const [cameraConfig, setCameraConfig] = useState({
    show: false,
    detalleId: null,
    nombreArchivo: ''
  });
  const [archivosPendientes, setArchivosPendientes] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  // ============ REFS ============
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  // ============ SELECTORES ============
  const current = useSelector(state => state.recibo.current || { recibos: [] });
  const bancos = useSelector(state => state.banco.bancos);
  const personas = useSelector(state => state.persona.personas);

  // ============ EFECTOS ============
  useEffect(() => {
    if (show) {
      dispatch(fetchBancos());
      dispatch(fetchPersonas());
      if (deposito?.id_recibo) {
        dispatch(fetchReciboById(deposito.id_recibo));
      }
      setArchivosPendientes({});
    }

    return () => {
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [show, dispatch, deposito]);

  // ============ FUNCIONES DE CÁMARA ============
  const startCamera = (detalleId) => {
    const nombreArchivo = `deposito-${detalleId}-${Date.now()}.jpg`;
    setCameraConfig({ show: true, detalleId, nombreArchivo });

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(stream => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch(err => {
        console.error("Error al acceder a la cámara:", err);
        Swal.fire('Error', 'No se pudo acceder a la cámara. Asegúrese de permitir los permisos.', 'error');
      });
  };

  const captureImage = () => {
    if (!cameraConfig.detalleId || !videoRef.current || !cameraConfig.nombreArchivo) {
      Swal.fire('Error', 'Complete el nombre del archivo antes de capturar', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(blob => {
      if (!blob) {
        Swal.fire('Error', 'No se pudo generar la imagen', 'error');
        return;
      }

      const file = new File([blob], cameraConfig.nombreArchivo, { type: 'image/jpeg' });
      
      setArchivosPendientes(prev => ({
        ...prev,
        [cameraConfig.detalleId]: file
      }));

      // Mostrar preview
      const detalleIndex = deposito.detalles.findIndex(d => d.id === cameraConfig.detalleId);
      if (detalleIndex !== -1) {
        const previewUrl = URL.createObjectURL(file);
        setImagenPreview({
          index: detalleIndex,
          url: previewUrl,
          nombre: cameraConfig.nombreArchivo
        });
        setImagenVisible(prev => {
          const newVisibility = [...prev];
          newVisibility[detalleIndex] = true;
          return newVisibility;
        });
      }

      // Detener cámara
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      setCameraConfig({ show: false, detalleId: null, nombreArchivo: '' });
      
      Swal.fire('Éxito', 'Imagen capturada y lista para guardar', 'success');
    }, 'image/jpeg', 0.9);
  };

  // ============ MANEJO DE ARCHIVOS ============
  const handleFileChange = async(e) => {
    const detalleId = e.target.getAttribute('data-detalle-id');
    const file = e.target.files?.[0];

    if (!file || !detalleId) return;

    const renamed = new File([file], `resdep-${file.name}`, { type: file.type });
    const renamedFile = await compressImage(renamed);
    setArchivosPendientes(prev => ({
      ...prev,
      [detalleId]: renamedFile
    }));

    // Mostrar preview
    const detalleIndex = deposito.detalles.findIndex(d => d.id === detalleId);
    if (detalleIndex !== -1) {
      const previewUrl = renamedFile.type.includes('image') ? URL.createObjectURL(file) : getUploadUrl('pdf-icon.png');
      setImagenPreview({
        index: detalleIndex,
        url: previewUrl,
        nombre: renamedFile.name
      });
      setImagenVisible(prev => {
        const newVisibility = [...prev];
        newVisibility[detalleIndex] = true;
        return newVisibility;
      });
    }
  };

  const toggleImageVisibility = (index, archivo) => {
    const newVisibility = [...imagenVisible];
    newVisibility[index] = !newVisibility[index];
    setImagenVisible(newVisibility);

    if (newVisibility[index]) {
      const detalle = deposito.detalles[index];
      const file = archivosPendientes[detalle.id];
      
      const url = file 
        ? (file.type.includes('image') ? URL.createObjectURL(file) : getUploadUrl('pdf-icon.png'))
        : getUploadUrl(archivo);

      setImagenPreview({
        index,
        url,
        nombre: file?.name || archivo
      });
    } else {
      setImagenPreview(null);
    }
  };

  // ============ GUARDAR COMPROBANTES ============
  const guardarComprobantes = async () => {
    const pendientes = Object.keys(archivosPendientes);
    
    if (pendientes.length === 0) {
      Swal.fire('Info', 'No hay respaldos de depósitos pendientes por guardar', 'info');
      return;
    }
  
    setIsSaving(true);
  
    try {
      // Solo verificar que todas las llamadas al backend fueron exitosas
      await Promise.all(
        pendientes.map(detalleId => 
          dispatch(updateDetalleArchivo({
            id: detalleId,
            file: archivosPendientes[detalleId]
          })).unwrap()
        )
      );
  
      // Cerrar modal inmediatamente después de éxito en el backend
      onHide(); 
      Swal.fire('Éxito', 'Respaldos de depósitos guardados correctamente', 'success');
      
    } catch (error) {
      console.error('Error al guardar:', error);
      Swal.fire('Error', 'Ocurrió un problema al guardar los respaldos', 'error');
    } finally {
      setIsSaving(false);
    }
  };
  // ============ IMPRIMIR RECIBO ============
  const handleImprimirRecibo = () => {
    if (!current) return;

    const totalLiteral = numeroALetras(current.monto || 0);
    setReciboData({
      recibo: current,
      reciboDetalles: current.reciboDets || [],
      totalLiteral,
    });
    setShowImprimirReciboModal(true);
  };

  if (!deposito) return null;

  // ============ RENDER ============
  return (
    <>
      {/* Modal Principal */}
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Detalles del Depósito</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          {/* Input de archivo oculto */}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*,.pdf"
            style={{ display: 'none' }}
          />

          {/* Datos Generales */}
          <div className="mb-4 p-3 bg-light rounded">
            <h5>Información del Depósito</h5>
            <div className="row">
              <div className="col-md-6">
                <p><strong>Fecha:</strong> {formatearFecha(deposito.fecha)}</p>
                <p><strong>Monto Total:</strong> {parseFloat(deposito.monto_total).toFixed(2)} Bs.</p>
              </div>
              <div className="col-md-6">
                <p><strong>CI:</strong> {deposito.ci}</p>
                <p><strong>Nombre:</strong> {
                 
                    personas.find(p => p.ci === deposito.ci) 
                      ? nombreApellidosFullPersona(personas.find(p => p.ci === deposito.ci), deposito.ci)
                      : 'No registrado'
                
                }</p>
              </div>
            </div>
          </div>

          {/* Preview de Imagen */}
          {imagenPreview && (
            <div className="position-absolute end-0 top-0 m-3" style={{ zIndex: 1000 }}>
              <div className="bg-white p-2 shadow rounded">
                <Image
                  src={imagenPreview.url}
                  alt="Comprobante"
                  fluid
                  style={{ maxHeight: '200px' }}
                />
                <div className="text-center small mt-2">{imagenPreview.nombre}</div>
                <Button 
                  variant="link" 
                  size="sm" 
                  onClick={() => setImagenPreview(null)}
                  className="text-danger"
                >
                  Cerrar
                </Button>
              </div>
            </div>
          )}

          {/* Tabla de Detalles */}
          <div className="mb-4">
            <h5>Detalles Bancarios</h5>
            {deposito.detalles?.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead className="table-primary">
                  <tr>
                    <th>#</th>
                    <th>Banco</th>
                    <th>Cuenta</th>
                    <th>Fecha Dep.</th>
                    <th>Monto (Bs)</th>
                    <th>N° Ref. Dep.</th>
                    <th>Foto de respaldo</th>
                  </tr>
                </thead>
                <tbody>
                  {deposito.detalles.map((detalle, index) => {
                    const banco = bancos.find(b => b.id === detalle.id_banco);
                    const archivoPendiente = !!archivosPendientes[detalle.id];
                    const tieneArchivo = !!detalle.archivo || archivoPendiente;
                    const nombreArchivo = archivoPendiente 
                      ? archivosPendientes[detalle.id].name 
                      : detalle.archivo;
                    const fotoDetalle = archivoPendiente? URL.createObjectURL(archivosPendientes[detalle.id]) :detalle.archivo? getUploadUrl(detalle.archivo): ""

                    return (
                      <tr key={detalle.id}>
                        <td>{index + 1}</td>
                        <td>{banco?.sigla || 'N/A'}</td>
                        <td>{banco?.cuenta_bancaria || 'N/A'}</td>
                        <td>{formatearFecha(detalle.fecha_deposito)}</td>
                        <td className="text-end">{detalle.monto} Bs.</td>
                        <td>{detalle.docum}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {/* Ícono de ojo */}
                            <Button
                              variant={tieneArchivo ? "outline-primary" : "outline-secondary"}
                              size="sm"
                              onClick={() => toggleImageVisibility(index, detalle.archivo)}
                              disabled={!tieneArchivo}
                              title={tieneArchivo ? 'Ver comprobante' : 'No hay comprobante'}
                            >
                              {tieneArchivo ? (imagenVisible[index] ? <BsEyeSlash /> : <BsEye />) : <BsEyeSlash />}
                            </Button>

                            {/* Botón para subir archivo */}
                            <Button
                              variant={archivoPendiente ? "warning" : "outline-primary"}
                              size="sm"
                              className="mx-2"
                              onClick={() => {
                                fileInputRef.current?.setAttribute('data-detalle-id', detalle.id);
                                fileInputRef.current?.click();
                              }}
                              title="Subir archivo"
                            >
                              <BsUpload />
                            </Button>

                            {/* Botón para cámara */}
                            <Button
                              variant={archivoPendiente ? "warning" : "outline-success"}
                              size="sm"
                              onClick={() => startCamera(detalle.id)}
                              title="Tomar foto"
                            >
                              <BsCamera />
                            </Button>

                            {/* Nombre del archivo */}
                            {tieneArchivo && (
                              <span 
                                className={`ms-2 small text-truncate ${archivoPendiente ? 'text-warning fw-bold' : ''}`}
                                style={{ maxWidth: '150px' }}
                                title={nombreArchivo}
                              >
                                {archivoPendiente ? '(Pendiente) ' : ''}
                                <a
                                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                                    href={fotoDetalle}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title='Click para ver en una nueva pestaña'
                                >
                                    {nombreArchivo?.split('-').pop() || 'Comprobante'}
                                </a>
                              </span>




                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Alert variant="info">No hay detalles disponibles</Alert>
            )}
          </div>

          {/* Detalles del Recibo */}
          <div className="mb-3">
            <h5>Detalles del Recibo</h5>
            {current?.reciboDets?.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead className="table-secondary">
                  <tr>
                    <th>#</th>
                    <th>Concepto</th>
                    <th>Cantidad</th>
                    <th>P. Unitario</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody>
                  {current.reciboDets.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item.concepto}</td>
                      <td>{item.cantidad}</td>
                      <td>{parseFloat(item.pu).toFixed(2)} Bs.</td>
                      <td>{parseFloat(item.monto).toFixed(2)} Bs.</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Alert variant="warning">No hay detalles de recibo</Alert>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button 
            variant="success" 
            onClick={guardarComprobantes}
            disabled={Object.keys(archivosPendientes).length === 0 || isSaving}
          >
            {isSaving ? (
              <>
                <Spinner size="sm" animation="border" className="me-2" />
                Guardando...
              </>
            ) : (
              <>
                <BsSave className="me-2" /> 
                Guardar Respaldos ({Object.keys(archivosPendientes).length})
              </>
            )}
          </Button>
          
          <Button variant="primary" onClick={handleImprimirRecibo}>
            <BsPrinter className="me-2" /> Imprimir Recibo
          </Button>
          
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Cámara */}
      <Modal show={cameraConfig.show} onHide={() => {
        if (videoRef.current?.srcObject) {
          videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
        setCameraConfig({ show: false, detalleId: null, nombreArchivo: '' });
      }} centered>
        <Modal.Header closeButton className="bg-dark text-white">
          <Modal.Title>Capturar Comprobante</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <video 
            ref={videoRef}
            autoPlay
            playsInline
            muted
            className="img-fluid rounded border mb-3"
            style={{ maxWidth: '100%', maxHeight: '400px' }}
          />
          <div className="form-group">
            <label>Nombre del archivo:</label>
            <input
              type="text"
              className="form-control"
              value={cameraConfig.nombreArchivo}
              onChange={(e) => setCameraConfig(prev => ({ ...prev, nombreArchivo: e.target.value }))}
              placeholder="Ej: comprobante-deposito.jpg"
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="danger" 
            onClick={() => {
              if (videoRef.current?.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
              }
              setCameraConfig({ show: false, detalleId: null, nombreArchivo: '' });
            }}
          >
            Cancelar
          </Button>
          <Button 
            variant="success" 
            onClick={captureImage}
            disabled={!cameraConfig.nombreArchivo}
          >
            <BsCamera className="me-2" /> Capturar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Impresión */}
      {showImprimirReciboModal && reciboData && (
        <ImprimirRecibo
          show={showImprimirReciboModal}
          handleClose={() => setShowImprimirReciboModal(false)}
          recibo={reciboData.recibo}
          reciboDetalles={reciboData.reciboDetalles}
          totalLiteral={reciboData.totalLiteral}
        />
      )}
    </>
  );
};

export default DepositoForm;