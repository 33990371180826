import React, { useState } from "react";
import { Modal, Tabs, Tab, Button } from "react-bootstrap";
import TabPagoRespaldo from "./TabPagoRespaldo"; // Importar el componente TabPagoRespaldo
import VistaGeneralProyecto from "./VistaGeneralProyecto"; // Importar el componente de Vista General
import { useDispatch } from "react-redux";
import { registrarProyectoPagoRealizado } from "../../reducers/proyecto_slice";
import Swal from "sweetalert2";

const FormPagoRespaldo = ({ showModal, handleClose, proyecto, tipo }) => {
  const [key, setKey] = useState("pago"); // Controlar las pestañas activas
  const [imagenes, setImagenes] = useState([]); // Almacenar las imágenes seleccionadas
  const dispatch = useDispatch()
  // Función para manejar el envío de los depósitos
  const handleSubmitDepositos = () => {
     let mensaje=` <div>Se guardarán <strong>${imagenes.length} foto(s)</strong> de depósitos</div>`
            // <ul style="list-style: none; padding: 0">`

            // mensaje+=`</ul><h3> <strong>Total: ${totalPagado} Bs. </strong></h3>
            // <div>Tipo de pago: <strong>${tipoPago} </strong></div>`
            Swal.fire({
                title: `Confirmar registro de ${imagenes.length} foto(s)`,
                html: mensaje,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, continuar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const formData = new FormData();
                        imagenes.forEach((imagen, index) => {
                            formData.append(`respaldo_${index}`, imagen); // Agregar cada imagen al FormData
                        });
                        dispatch(registrarProyectoPagoRealizado({ id: proyecto.id, respaldosDep: formData }))
                          .then(() => {
                                handleClose()
                                setImagenes([])
                          })
                    } catch (error) {
                        Swal.fire('Error', 'No se pudo guardar las fotos de los depósitos', 'error');
                    }
                }
            });
   
    // Aquí puedes realizar la llamada al backend para enviar los datos
  };
  const imagenesRespaldos = proyecto?.respaldosDep || null
  const titulo = tipo==="registrar"?"Registrar Depósitos":"Pagos realizados";
 
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="form-pago-respaldo-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          {/* Pestaña de Pago */}
          <Tab eventKey="pago" title="Pago">
            <TabPagoRespaldo montoPorPagar={proyecto?.monto_por_pagar} proyId={proyecto?.id} imagenes={imagenesRespaldos} setImagenes={setImagenes} tipo={tipo}/> {/* Pasar setImagenes */}
          </Tab>
          {/* Pestaña de Vista General */}
         <Tab 
                eventKey="vistaGeneral" 
                title={
                    <>
                    {proyecto?.comentario2 ? (
                            <span style={{ color: '#bc8600', marginLeft: '5px' }}>
                                   Vista General Proyecto  <i className="bi bi-exclamation-triangle"></i></span> 
                        ):(
                            <>Vista General Proyecto</>
                        )}
                        
                    </>
                }
            >
            <VistaGeneralProyecto proyecto={proyecto} />
          </Tab>
        </Tabs>
        {/* Botones alineados */}
        <div className="d-flex justify-content-end mt-3">
          <button className="button-mine btn-cancel-outline me-2" onClick={handleClose}  type="button">
            Cerrar
          </button>
        {tipo==="registrar" &&  <button className="button-mine  btn-money-outline" type="button"
            onClick={handleSubmitDepositos} disabled={imagenes.length === 0}>
                <i className="bi bi-wallet2"></i> Registrar Pago
            </button>}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FormPagoRespaldo;