import React from 'react';
import CustomPrintModal3 from '../UI/CustomPrintModal3';
import { formatCurrency2 } from '../../util/valueCalculator';
import { formatearFechaSimple } from '../../util/customFormatter';

const ImprimirCajaDiaria = ({ show, handleClose, cajaDiaria, detallesRecibo, totals, usuario, nombre,copia }) => {
    const formattedDate = formatearFechaSimple(cajaDiaria.fechaAper);
    const nombreCompletoPersona = `${nombre}`;
    //console.log("totals=",totals)
    // Configurar el contenido de la tabla con las columnas adicionales
    const ingresosCaja = detallesRecibo
    .filter(det => det.pago === 'C' && det.tipo === 'INGRESO' && det.fecha === cajaDiaria.fechaAper)
    .reduce((sum, det) => sum + (parseFloat(det.pagado) || 0), 0);

    const egresosCaja = detallesRecibo
            .filter(det => det.pago === 'C' && det.tipo === 'EGRESO' && det.fecha === cajaDiaria.fechaAper)
            .reduce((sum, det) => sum + (parseFloat(det.pagado) || 0), 0);

    const ingresosBanco = detallesRecibo
            .filter(det => det.pago === 'B' && det.tipo === 'INGRESO' && det.fecha === cajaDiaria.fechaAper)
            .reduce((sum, det) => sum + (parseFloat(det.pagado) || 0), 0);

    const egresosBanco = detallesRecibo
            .filter(det => det.pago === 'B' && det.tipo === 'EGRESO' && det.fecha === cajaDiaria.fechaAper)
            .reduce((sum, det) => sum + (parseFloat(det.pagado) || 0), 0);
    const content = {
        head: [
            ['Nro Recibo', 'Concepto', 'ING_CAJA', 'ING_BANCO'],
        ],
        body: [
            ...detallesRecibo
                .filter(det => det.id_caja === cajaDiaria.id_caja && det.fecha === cajaDiaria.fechaAper)
                .map(detalle => [
                    detalle.nroRecibo,
                    detalle.concepto,
                    detalle.pago === 'C' && detalle.tipo === 'INGRESO' ? formatCurrency2(detalle.pagado) : '',
                    //detalle.pago === 'C' && detalle.tipo === 'EGRESO' ? formatCurrency2(detalle.pagado) : '',
                    detalle.pago === 'B' && detalle.tipo === 'INGRESO' ? formatCurrency2(detalle.pagado) : '',
                    //detalle.pago === 'B' && detalle.tipo === 'EGRESO' ? formatCurrency2(detalle.pagado) : '',
                ]),
            [
                '', // Celda vacía para alinear los totales
                'Totales',
                formatCurrency2(ingresosCaja),
             //   formatCurrency2(egresosCaja),
                formatCurrency2(ingresosBanco),
              //  formatCurrency2(egresosBanco),
            ],
        ],
    };

    // Calcular el saldo incluyendo el monto inicial
    const saldo = ingresosCaja + parseFloat(cajaDiaria.montoInicial || 0) - egresosCaja;


    return (
        <CustomPrintModal3
            show={show}
            handleClose={handleClose}
            title1="LIBRO DIARIO DE CAJA"
            title2={`FECHA: ${formattedDate}  CAJA: ${cajaDiaria.nrocaja}  USUARIO: ${nombreCompletoPersona}`}
            title3={`MONTO INICIAL CAJA: ${formatCurrency2(cajaDiaria.montoInicial)}`}
            title4=""
            title5=""
            total1={`INGRESOS CAJA: ${formatCurrency2(ingresosCaja)}    SALDO EN CAJA: ${formatCurrency2(saldo)}`}
            total2={`INGRESOS BANCO: ${formatCurrency2(ingresosBanco)} `}
            content={content}
            copia={copia}
        />
    );
};

export default ImprimirCajaDiaria;
