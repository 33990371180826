import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService'; // Importa tu servicio API

// Crear una instancia del servicio para la entidad "proyectos"
const proyectoService = apiService('/proyectos');

// Thunks para las operaciones CRUD
export const fetchProyectos = createAsyncThunk('proyecto/fetchProyectos', async () => {
  const response = await proyectoService.getAll();
  return response || [];
});
// Thunks para las operaciones CRUD
export const fetchLotes = createAsyncThunk('proyecto/fetchProyectos', async () => {
  const response = await proyectoService.getAllBySomeId("lotes","");
  return response || [];
});
export const fetchProyectoById = createAsyncThunk('proyecto/fetchProyectoById', async (id) => {
  const response = await proyectoService.getById(id);
  return response;
});
export const fetchProyectosByUsuario = createAsyncThunk('proyecto/fetchProyectos', async () => {
  const response = await proyectoService.getAllBySomeId("usuario","");
  return response || [];
});
export const fetchLotesByUsuario = createAsyncThunk('proyecto/fetchProyectos', async () => {
  const response = await proyectoService.getAllBySomeId("usuario/lotes","");
  return response || [];
});
export const addProyecto = createAsyncThunk('proyecto/addProyecto', async (proyecto, { rejectWithValue }) => {
  try {
    const response = await proyectoService.createWithFormData(proyecto);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addProyectoConAntecedente = createAsyncThunk('proyecto/addProyecto', async (proyecto, { rejectWithValue }) => {
  try {
    const response = await proyectoService.createWithFormDataByEndpoint("/create-con-antecedente",proyecto);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const modifyProyecto = createAsyncThunk('proyecto/modifyProyecto', async ({ id, proyecto }, { rejectWithValue }) => {
  try {
    
    const response = await proyectoService.updateWithFormData(id, proyecto);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const registrarProyectoPagoRealizado = createAsyncThunk('proyecto/modifyProyecto', async ({ id, respaldosDep }, { rejectWithValue }) => {
  try {
    
    const response = await proyectoService.updateSomeDato("/pago-respaldos",id, respaldosDep);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const resellarProyecto = createAsyncThunk('proyecto/resellarProyecto', async ({ id }, { rejectWithValue }) => {
  try {
    
    const response = await proyectoService.updateSomeDatoJ("resellar", id, {});
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const realizarPaseProfesional = createAsyncThunk('proyecto/resellarProyecto', async ({ id, data }, { rejectWithValue }) => {
  try {
    
    const response = await proyectoService.updateSomeDato("paseprofesional", id, data);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const transferirProyecto = createAsyncThunk('proyecto/resellarProyecto', async ({ id, data }, { rejectWithValue }) => {
  try {
    
    const response = await proyectoService.updateSomeDato("transferir", id, data);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeProyecto = createAsyncThunk('proyecto/removeProyecto', async (id, { rejectWithValue }) => {
  try {
    await proyectoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const cancelarProcesoEspecial = createAsyncThunk('proyecto/removeProyecto', async (id, { rejectWithValue }) => {
  try {
    await proyectoService.removeType(id,"cancelar");
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  proyectos: [],
  current: null,
  statusSend: 'idle',
  statusGet: 'idle',
  error: {
    status: null,
    messages: []
  },
};

// Definición del slice
const proyectoSlice = createSlice({
  name: 'proyecto',
  initialState,
  reducers: {
    setCurrentProyecto(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProyectos.fulfilled, (state, action) => {
        state.proyectos = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchProyectos.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchProyectos.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchProyectoById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addProyecto.fulfilled, (state, action) => {
        state.proyectos.unshift(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyProyecto.fulfilled, (state, action) => {
        const index = state.proyectos.findIndex(proyecto => proyecto.id === action.payload.id);
        if (index !== -1) {
          // Remover el proyecto editado de su posición original
          const [editedProyecto] = state.proyectos.splice(index, 1);
          // Insertarlo al inicio del array
          state.proyectos.unshift(editedProyecto);
          // Actualizar el proyecto con los datos nuevos
          Object.assign(state.proyectos[0], action.payload);
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyProyecto.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(resellarProyecto.fulfilled, (state, action) => {
        state.proyectos.unshift(action.payload);
        const index = state.proyectos.findIndex(proyecto => proyecto.id === action.payload.id_proyecto_resellado);
        if (index !== -1 && action.payload.proyectoPrevio) {
          state.proyectos[index] = {
              ...state.proyectos[index], 
              num_visado: action.payload.proyectoPrevio.num_visado,
              estado: action.payload.proyectoPrevio.estado,
              comentario1:action.payload.proyectoPrevio.comentario1,
              updatedAt:action.payload.proyectoPrevio.updatedAt,
              recibos:[]
          };
      }
        state.statusSend = 'idle';
      })
      .addCase(resellarProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(resellarProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(removeProyecto.fulfilled, (state, action) => {
        state.proyectos = state.proyectos.filter(proyecto => proyecto.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentProyecto, resetStatusSend } = proyectoSlice.actions;

export default proyectoSlice.reducer;
