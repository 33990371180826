import { useState } from "react";
import imageCompression from 'browser-image-compression'

const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 800,
    useWebWorker: true
    };

const validDocTypes = [
    'application/pdf',  // PDF
    'application/msword',  // Word (.doc)
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // Word (.docx)
    'application/vnd.ms-excel',  // Excel (.xls)
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  // Excel (.xlsx)
];
const validDocAndImageTypes = [
    'application/pdf',  // PDF
    'image/jpeg',  // JPEG image
    'image/png',  // PNG image
];
const esImage = (fileName) => {
    if (typeof fileName !== 'string') {
        return true;
    }
    if(fileName.endsWith('.png') || fileName.endsWith('.jpeg') || fileName.endsWith('.jpg'))
        return true

    else return false
}

const esDoc = (fileName) => {
    if (typeof fileName !== 'string') {
        return true;
    }

    if(fileName.endsWith('.pdf'))
        return true
    else return false
}
export function validationFn(type, file){
    if (!file) return false;
    
    if((type === "image" && file.type?.startsWith('image/') && esImage(file.name))|| (!file.type && esImage(file)))
        return "image";

  
    if (type === "doc/image") {
        if ((file.type && file.type.startsWith('image/') && esImage(file.name)) || (!file.type && esImage(file))) {
            return "image";  // Es una imagen
        } else if (file.type === 'application/pdf' ||  (!file.type &&esDoc(file))) {
            return "doc";  // Es un documento PDF
        }
    }
    else return false;
}
export async function compressImage(image) {
    const compressedFile = await imageCompression(image, options);

    // Obtener la extensión del archivo
    const fileExtension = image.name.split('.').pop();

    // Normalizar el nombre del archivo
    const fileNameWithoutExtension = image.name
        .replace(/\s+/g, '-') // Reemplazar espacios por guiones
        .replace(/_/g, '-') // Reemplazar guiones bajos por guiones
        .normalize("NFD") // Normalizar para separar caracteres con tildes
        .replace(/[\u0300-\u036f]/g, '') // Eliminar tildes
        .replace(/ñ/g, 'n') // Reemplazar la ñ por n
        .replace(new RegExp(`.${fileExtension}$`), ''); // Eliminar la extensión del archivo

    // Crear el nuevo nombre del archivo
    const newFileName = `${fileNameWithoutExtension}-compr.${fileExtension}`;

    // Crear un nuevo archivo con el nombre actualizado
    const compressedFileWithName = new File(
        [compressedFile],
        newFileName,
        { type: compressedFile.type }
    );

    return compressedFileWithName;
}
export function useInputFile(tipo, compress = true) {
    const [file, setFile] = useState(null);
    const [validType, setValidType] = useState(null);
    const [isValid, setIsValid] = useState(true);

    async function handleFileChange(newFile) {
        if(newFile){const validedType = validationFn(tipo, newFile)
        if (validedType === "image") {
            try {
                let processedFile = newFile;
                if (compress) {
                    processedFile = await compressImage(newFile); // Comprimir solo si compress es true
                }
                setFile(processedFile);
                setIsValid(true);
            } catch (error) {
                setIsValid(false);
            }
        } else if(validedType === "doc"){
            setFile(newFile);
            setIsValid(true);
        } else {  setFile(null);
                  setIsValid(false);}
        setValidType(validedType)
    }else{
        setFile(null)
        setValidType(null)
        setIsValid(true)
    }
    }

    function resetInputFile() {
        setFile(null);
        setIsValid(true);
    }

    return {
        file,
        tipo:validType,
        resetInputFile,
        handleFileChange,
        isValid
    };
}